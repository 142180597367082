@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

* {
  font-family: "Roboto", sans-serif !important;
  /* overflow-x: hidden; */
}

body {
  font-size: 15px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f51313;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f51313;
}

.primary-btn {
  background: #f51313;
  color: white;
}
.s_Product_carousel .owl-dots div.active {
  background: #f51313;
}
.product_description_area .nav.nav-tabs li a.active {
  background: #f51313;
}
.product_description_area .tab-content .total_rate .box_total h4 {
  color: #f51313;
}
.alert {
  background: #6b6a71;
  color: white;
  border: none;
}
.gradient-bg-reverse,
#search_input_box {
  background: #6b6a71;
}
@media only screen and (max-width: 750px) {
  .single-product {
    width: 50%;
  }
}
.pagination .dot-dot:hover {
  border-right: none;
}
.pagination {
  border-left: none;
}

.billing_details .contact_form .form-group .country_select .list {
  height: 300px;
  overflow-y: scroll;
}

.single-footer-widget > ul > li > a {
  color: gray !important;
  cursor: pointer;
}
.single-footer-widget > ul > li > a:hover {
  color: white !important;
}
.nice-select {
  width: 100%;
  display: none;
}

.country_select > .list {
  width: 100%;
  border-radius: 0px;
  height: 150px;
  overflow-y: scroll;
}
select2
  select2-container
  select2-container--default
  select2-container--below
  select2-container--focus {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .feature-icon {
    margin-left: 3em;
    margin-right: 4rem;
  }
}

.feature-icon {
  border-right: solid 1px #fff;
  cursor: pointer;
  border-bottom: solid 1px #fff;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0);
  transform: translateY(0px);
  box-shadow: 0px 0px 0px rgb(25 30 35 / 0%);
  transition: all 0.4s;
  transition-timing-function: cubic-bezier(0.03, 0.5, 0.56, 0.99);
}
.feature-icon:hover {
  background-color: rgba(255, 255, 255, 1) !important;
  transform: translateY(-10px) !important;
  box-shadow: 0px 15px 30px rgb(25 30 35 / 30%) !important;
}

.single-features {
  border: none;
  margin-top: 30px;
}

.carousel-indicators li {
  border-radius: 10px;
  width: 15px;
  height: 15px;
}

.purchase-block {
  color: #f51313;
  box-shadow: 5px 10px 15px 0px #888888;
  padding: 3em;
  border-radius: 5px;
  border: 1px solid lightgray;
  border-left: 10px solid #f51323;
  margin-bottom: 3em;
}

table thead th {
  text-align: center;
  max-width: 129px;
  vertical-align: middle;
  border-bottom: 2px solid #dee2e6;
}

/*
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/

table {
  width: 100%;
  border-collapse: collapse;
}

table {
  width: 100%;
  border-collapse: collapse;
}
/* Zebra striping */
tr:nth-of-type(odd) {
  background: #eee;
}
th {
  background: #f51313;
  color: white;
  font-weight: bold;
}

.table td,
.table th {
  text-align: center;
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #dee2e6;
  width: auto;
}

a:hover {
  text-decoration: none !important;
}

.login_parent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fa8585;
}

.input_form_control {
  width: 300px;
}

.login_parent button {
  width: 300px;
}

.login-card div {
  box-shadow: 1px 4px 9px grey;
  display: flex;
  flex-direction: column;
}

.swal2-confirm {
  background-color: #fa8585 !important;
}

.swal2-default-outline:focus {
  box-shadow: none !important;
}

.primary-button {
  transition: 0.3s;
  background-color: #fa8585;
  border: none;
  border-radius: 5px;
  color: #fff !important;
  font-size: 16px;
  cursor: pointer;
  padding: 10px 25px;
}

.primary-button:hover {
  background-color: #f73b3b !important;
}

.login-container {
  height: 100vh;
  width: 100vw;
  background-color: #fa8585;
}

.main-body {
  max-width: 100%;
  min-height: 100vh;
  background-color: #fa8585;
}

.ant-collapse {
  background-color: #fff;
}

.heading-text {
  font-size: 34px;
  font-family: Ortica;
  font-weight: 400;
  color: #fff;
  letter-spacing: 1px;
}

.ant-collapse-header-text {
  font-weight: 600;
}

.main-body-parent {
  display: flex;
}

.ps-menu-button {
  color: #000 !important;
}

a {
  color: #000 !important;
}

.css-dip3t8 {
  background-color: #fff !important;
}

.navbar-top-fixed {
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.12) 0px 2px 10px 0px;
  position: fixed;
  width: 100%;
  z-index: 999;
}

.navbar-top-fixed a img {
  height: 50px;
}

.main-body-parent {
  padding-top: 55px;
}

.ant-collapse .ant-collapse-content {
  background-color: #f8f8f8;
}

.ant-collapse-header {
  letter-spacing: 1px;
}

input {
  display: block;
  width: 100%;
  height: 39px !important;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  outline: 0 !important;
  box-shadow: none !important;
  font-family: "Roboto";
}

.css-g1d714-ValueContainer {
  height: 39px !important;
}

.pagenotfound-parent {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagenotfound-parent h1 {
  font-weight: 600;
  color: #fff;
}

.ps-sidebar-root {
  position: fixed !important;
  height: 100vh;
  z-index: 998;
}

.main-body {
  padding-left: 265px;
}

.ant-pagination-item-active {
  border-color: #f51313 !important;
}

.btn-table-action {
  padding-left: 13px !important;
  padding-right: 13px !important;
}

.ant-select-selector {
  height: 39px !important;
}

.ant-select-selection-item,
.ant-select-selection-placeholder {
  display: flex;
  align-items: center;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: #495057 !important;
}

.ant-select:not(.ant-select-customize-input) {
  background-color: #e9ecef !important;
}

.form-control:disabled,
.form-control[readonly] {
  cursor: not-allowed !important;
}

table thead th {
  min-width: 150px !important;
}

.info-box {
  /* height: 150px; */
  background-color: #f8f8f8;
  padding: 10px 15px 14px;
  border-radius: 10px;
  margin: 10px 0;
  padding: 15px;
}

.info-box > h4 {
  font-family: Ortica !important;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 20px;
}

.info-box > div > h3 {
  font-size: 30px !important;
  font-family: Roboto;
  font-weight: 700 !important;
  color: #f51313;
}

.info-box > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info-box > div > svg {
  font-size: 40px !important;
  font-family: Roboto;
  font-weight: 700 !important;
  color: #f51313;
}
